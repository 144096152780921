export default {
  h1FontFamily: 'Poppins',
  desktopTitlePadding: '5%',
  textBlockMobileDisplay: 'block',
  textBlockMobileFontWeight: 'bold',
  textBlockDesktopFontWeight: 'bold',
  campaignWrapperBackground: 'var(--white)',
  campaignTextColor: 'var(--black)',
  formBlockColor: 'var(--white)',
  mobileAspectRatio: '17 / 9',
  tabletAspectRatio: '20 / 9',
  desktopAspectRatio: '26 / 9',
  maxWrapperWidth: '78rem',
  maxWrapperWidthDownFromDesktop: '58rem',
  maxWrapperWidthUpFromLargeDesktop: '100rem',
  layoutPadding: '5rem 5% 1rem',
  topTitlePadding: '1rem',
  foregroundTranslate: 'unset',
  foregroundMobileTranslate: 'unset',
  mobileForegroundHeight: '10rem',
  mobileForegroundWidth: 'auto',
  mobileForegroundDisplay: 'none',
  textShadow: 'none',
  bgLayerHeight: '100%',
};

